import {ISerializable} from '@netvision/lib-history';
import {BinaryOperator} from '@netvision/lib-api-repo';
import {
  CompareCellConfig,
  Comparison,
  WidgetSchema,
  SchemaJournal,
  LocaleCodes,
  PublishPayloadData,
  Locales,
  LocalSettings,
  GroupSettings,
  ComparisonValue
} from './IWidgetProps';
import {cloneDeep} from 'lodash-es';

const paginationKeyMap = new Map([
  ['offset', 'first'],
  ['limit', 'rows']
]);
const filterOmitKeySet = new Set([...paginationKeyMap.keys(), 'sort', 'detailedId']);
export const dateTimeKeys = ['startDt', 'endDt', 'createdAt', 'updatedAt', 'timestamp'];

export const filterTypesDict = new Map<string, BinaryOperator>([
  ['text', 'contains'],
  ['enum', 'equals'],
  ['number', 'equals'],
  ['dateTime', 'inDateRange']
]);

export const cleanFilterProps = <T extends PublishPayloadData<ISerializable>['payload']>(
  payload: T | ISerializable
) => {
  return Object.entries(payload).reduce<Record<string, any>>((acc, [key, value]) => {
    if (value) acc[key] = value;
    return acc;
  }, {});
};

export const actializeLocalState = (remote: SchemaJournal, local: LocalSettings, locales: Locales) => {
  if (!local.columns) {
    return {
      ...remote,
      isOpened: local.isOpened || false,
      isGrouped: local.isGrouped || false,
      locales
    };
  }

  const actualOrder = [...new Set([...local.order, ...remote.order])].filter((item) => remote.order.includes(item));

  const actualGroups = !remote.groups?.length
    ? actualOrder.reduce<GroupSettings[]>((acc, next) => {
        acc.push({
          field: next,
          permanent: false,
          isActive: false
        });
        return acc;
      }, [])
    : [
        ...[...remote.groups, ...(local.groups || [])]
          .reduce<Map<string, GroupSettings>>((acc, next) => {
            if (acc.has(next.field)) {
              const isPermanent = Boolean(acc.get(next.field)?.permanent);
              const existingGroup = acc.get(next.field);

              if (existingGroup) {
                existingGroup.permanent = isPermanent;
                existingGroup.isActive =
                  isPermanent || Boolean(local.groups?.find(({field}) => field === next.field)?.isActive);
              }
            } else {
              acc.set(next.field, {...next});
            }
            return acc;
          }, new Map())
          .values()
      ].filter(({field}) => remote.order.includes(field));

  const actualColumns = Object.entries(remote.columns || {}).reduce<SchemaJournal['columns']>(
    (acc, [columnKey, columnValue]) => {
      acc[columnKey] = cloneDeep(columnValue);

      if (local.columns?.[columnKey]) {
        if (typeof local.columns[columnKey].hidden !== 'undefined') {
          acc[columnKey].hidden = local.columns[columnKey].hidden;
        }

        if (local.columns[columnKey].permanent) {
          acc[columnKey].permanent = true;
        }
      }

      return acc;
    },
    {}
  );

  return {
    order: actualOrder,
    groups: actualGroups,
    columns: actualColumns,
    isOpened: local.isOpened || false,
    isGrouped: local.isGrouped,
    locales
  };
};

export const checkIsQFilterKey = (key: BinaryOperator) => {
  return !filterOmitKeySet.has(key);
};

export const getPaginationKey = (key: string) => {
  return paginationKeyMap.get(key);
};

export const getQueryOperator = (key: string) => {
  return filterTypesDict.get(key) || 'contains';
};

export const getQueryValues = (key: string, values: unknown) => {
  if (!values) return [];

  if (dateTimeKeys.includes(key)) {
    return String(values)
      .split(',')
      .map((val) => new Date(parseInt(val)).toUTCString())
      .flat();
  }

  return String(values)
    .split(',')
    .map((val) => [val, val.toUpperCase(), val.toLowerCase(), val[0]?.toUpperCase() + val.substring(1)?.toLowerCase()])
    .flat();
};

export const getTimestamp = (value: any, multiplier = 1): number | string => {
  const date = new Date(value);

  if (date.toString() === 'Invalid Date') {
    return !isNaN(value) ? Number(value) * multiplier : value.toString();
  } else {
    return date.getTime() * multiplier;
  }
};

export const parseComparableData = (entity: Record<string, any>, locales: Locales, locale: LocaleCodes) => {
  return Object.entries(entity).reduce<Comparison['secondStateData']>((acc, [key, value]) => {
    const localeKey = typeof locales[locale][key] === 'object' ? locales[locale][key].key : locales[locale][key] || key;
    const localeValue = locales[locale].enumOptions?.[key]?.[value] || locales[locale][value] || value;
    acc[key] = {
      key: localeKey,
      value: localeValue
    };
    return acc;
  }, {});
};

export const prepareComparable = (
  row: Record<string, any>,
  cell: string,
  locale: LocaleCodes,
  auxilaryMetadata: Map<string, WidgetSchema[]> | null,
  config?: CompareCellConfig
): Record<string, Comparison> | null => {
  if (!config) return null;
  const {wasKey, isKey} = config;
  const entity: Record<string, Record<string, any>> = typeof row[cell] === 'string' ? JSON.parse(row[cell]) : row[cell];
  const firstState = entity[wasKey] || null;
  const secondState = entity[isKey] || null;

  const locales = auxilaryMetadata?.get(cell)?.find(({id}) => id.endsWith(row.entityType || 'LocalesDictionary'))
    ?.locales as Locales | undefined;

  if (!locales) {
    return {
      [cell]: {
        firstStateData: Object.entries(firstState || entity).reduce<Record<string, ComparisonValue>>(
          (acc, [key, value]) => {
            acc[key] = {key, value};
            return acc;
          },
          {}
        ),
        secondStateData: Object.entries(secondState || entity).reduce<Record<string, ComparisonValue>>(
          (acc, [key, value]) => {
            acc[key] = {key, value};
            return acc;
          },
          {}
        )
      }
    };
  }

  if (!firstState || !secondState) {
    return {
      [cell]: {
        firstStateData: {},
        secondStateData: parseComparableData(entity, locales, locale)
      }
    };
  }

  const firstStateData = parseComparableData(firstState || entity, locales, locale);
  const secondStateData = parseComparableData(secondState || entity, locales, locale);

  return {
    [cell]: {
      firstStateData,
      secondStateData
    }
  };
};
