export const localesRU = {
  settingsColumnTitle: 'Отображать столбцы',
  settingsGroupsTitle: 'Группировать строки',
  actions: {
    refresh: 'Обновить',
    export: 'Экспорт',
    cancel: 'Отмена',
    back: 'Назад',
    resetFilters: 'Сбросить фильтры',
    openSettings: 'Настройки',
    byDefault: 'По умолчанию'
  },
  toastContent: {
    content: 'Событие не найдено',
    error: 'Ошибка',
    metadataLoadingError: 'Не удалось получить метаданные',
    dataLoadingError: 'Не удалось получить данные',
    countLoadingError: 'Не удалось получить данные о количестве элементов'
  }
}
