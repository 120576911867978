/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { MutableRefObject, useRef, useMemo, useEffect, useState } from 'react';
import { PublishPayload } from '@netvision/lib-types-frontend';
import { useWidgetProps } from '../hooks/useWidgetProps';
import { useData } from '../hooks/useData';
import { useMetadata } from '../hooks/useMetadata';
import { useSettings } from '../hooks/useSettings';
import { useSorting } from '../hooks/useSorting';
import { useFilters } from '../hooks/useFilters';
import { prepareComparable } from '../utils';
import { pick } from 'lodash-es';
import { name } from '../../package.json';

interface JournalMountProps {
  openDetailsPage: <T>(payload: PublishPayload<T>) => void
}

export const JournalMount = ({ openDetailsPage }: JournalMountProps) => {
  const mountingPointRef: MutableRefObject<HTMLDivElement | null> = useRef(null)
  const { mountChildren, areas, entityName, eventBusID, eventBus } = useWidgetProps()
  const { isLoading, journalData } = useData()
  const { auxilaryMetadata } = useMetadata()
  const { localSettingsState } = useSettings()
  const { filters, applyFilter } = useFilters()
  const { sorting, applySorting } = useSorting()
  const [widgetProps, setWidgetProps] = useState<Record<string, unknown>>(null!)

  const tableWidget = useMemo(() => (
    areas.find(({ name }) => name === 'table')
      ?.children.find(({ src }) => src === '@netvision/widget-react-table')
  ), [areas])

  useEffect(() => {
    if (!eventBusID) return

    eventBus.subscribe(eventBusID, 'filterUpdate', applyFilter)
    eventBus.subscribe(eventBusID, 'sortingApplied', applySorting)
    eventBus.subscribe(eventBusID, 'openDetails', openDetailsPage)

    return () => {
      eventBusID && eventBus.unsubscribe(eventBusID, 'filterUpdate')
      eventBusID && eventBus.unsubscribe(eventBusID, 'sortingApplied')
      eventBusID && eventBus.unsubscribe(eventBusID, 'openDetails')
    }
  }, [eventBus, eventBusID])

  useEffect(() => {
    if (!journalData || !tableWidget || !localSettingsState.columns) return
    if (!widgetProps) {
      const props = {
        ...tableWidget,
        props: {
          ...tableWidget.props,
          data: journalData,
          prepareComparable,
          auxilaryMetadata,
          eventBusID,
          filters,
          sorting,
          schema: {
            entityType: entityName,
            locales: localSettingsState.locales,
            isGrouped: localSettingsState.isGrouped,
            viewTable: pick(localSettingsState, ['columns', 'order', 'groups'])
          }
        }
      }
      setWidgetProps(props)
      const { current: mountingPoint } = mountingPointRef;
      if (mountingPoint) {
        mountChildren(mountingPoint, [props]);
      }
    }
  }, [journalData, localSettingsState, auxilaryMetadata]);

  useEffect(() => {
    if (widgetProps) {
      eventBus.notify(eventBusID, 'data:changed', {
        publisher: name,
        occurrenceTime: Date.now(),
        data: journalData
      })
    }
  }, [widgetProps, journalData]);

  return (
    <div
      ref={mountingPointRef}
      css={mountPointCSS}
      className={isLoading ? '--loading' : '--ready'}
      style={{
        visibility: localSettingsState.isOpened ? 'hidden' : 'visible',
        opacity: isLoading ? 0.5 : 1,
        pointerEvents: isLoading ? 'none' : 'all'
      }}
    />
  )
}

const mountPointCSS = css`
  font-size: 2rem;
  overflow: hidden;

  .single-spa-parcel-container {
    height: 100%;
    overflow: hidden;

    & > div {
      margin: 0;
      padding: 0;
      height: 100%;
      overflow: hidden;
    }
  }
`
