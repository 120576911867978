import React, {createContext, ReactNode, useContext, useEffect, useState} from 'react';
import {BinaryOperator, CubeFilter} from '@netvision/lib-api-repo';
import {PublishPayload} from '@netvision/lib-types-frontend';
import {history, ISerializable, SearchParams} from '@netvision/lib-history';
import {TableColumn, PublishPayloadData, DateTimePayload} from '../IWidgetProps';
import {useSearchParams} from './useSearchParams';
import {useWidgetProps} from './useWidgetProps';
import {useSettings} from './useSettings';
import {checkIsQFilterKey, cleanFilterProps, getQueryOperator, getQueryValues} from '../utils';
import {isEqual} from 'lodash-es';

interface FiltersProviderProps {
  children: ReactNode;
}

interface FiltersContext {
  filters: Map<string, string>;
  filterKeyPairs: Record<string, string>;
  setFilters: React.Dispatch<React.SetStateAction<Map<string, string>>>;
  applyFilter<T>(payload: PublishPayload<T>): void;
  combineQueryFilters: () => CubeFilter[];
}

const FiltersContext = createContext<FiltersContext>({
  filters: new Map(),
  filterKeyPairs: {},
  setFilters: () => {},
  applyFilter<T>(payload: PublishPayload<T>) {},
  combineQueryFilters: () => []
});

export const useFilters = () => useContext(FiltersContext);

export const FiltersProvider = ({children}: FiltersProviderProps) => {
  const {searchParams, setSearchParams} = useSearchParams();
  const {entityName} = useWidgetProps();
  const {localSettingsState} = useSettings();
  const [filters, setFilters] = useState<Map<string, string>>(new Map());
  const [filterKeyPairs, setFilterKeyPairs] = useState<Record<string, string>>({});

  const combineQueryFilters = () => {
    const queryFilters: CubeFilter[] = [];
    for (const [key, value] of filters) {
      const [keyPart] = key.split('.');
      const filterType = (localSettingsState.columns[keyPart]?.filter?.type || 'text') as string;

      if (checkIsQFilterKey(key as BinaryOperator)) {
        queryFilters.push({
          member: filterKeyPairs[keyPart],
          operator: getQueryOperator(filterType),
          values: getQueryValues(keyPart, value)
        });
      }
    }
    return queryFilters;
  };

  const setTimeFilters = (data: PublishPayloadData<Record<string, DateTimePayload>>) => {
    const [bKey, aKey] = data.sortingField.split('.');
    const targetKey = aKey || bKey;
    const timePayload = Object.entries(data.payload).reduce<Record<string, string>>((acc, [key, value]) => {
      acc[`${key}.${value.mode}`] = value.values.join(',');
      return acc;
    }, {});

    setSearchParams((prev) => {
      const prevTimeKey = Object.keys(prev).find((key) => key.startsWith(targetKey));
      prevTimeKey && delete prev[prevTimeKey];
      return cleanFilterProps({
        ...prev,
        ...timePayload,
        offset: 0
      });
    });
  };

  const setSimpleFilters = (data: PublishPayloadData<ISerializable>) => {
    setSearchParams((prev) => {
      return cleanFilterProps({
        ...prev,
        ...data.payload,
        offset: 0
      });
    });
  };

  const applyFilter = <T,>(payload: PublishPayload<T>) => {
    const data = payload.data as unknown as PublishPayloadData<T>;
    switch (data.sortingField) {
      case 'startDt':
      case 'endDt':
      case 'createdAt':
      case 'updatedAt':
      case 'timestamp':
      case `${entityName}.startDt`:
      case `${entityName}.endDt`:
      case `${entityName}.createdAt`:
      case `${entityName}.updatedAt`:
      case `${entityName}.timestamp`:
        setTimeFilters(data as unknown as PublishPayloadData<Record<string, DateTimePayload>>);
        break;
      default:
        setSimpleFilters(data as unknown as PublishPayloadData<ISerializable>);
    }
  };

  const pickFilters = (payload: ISerializable) => {
    return Object.entries(payload).reduce((acc, [key, value]) => {
      if (checkIsQFilterKey(key as BinaryOperator)) {
        acc.set(key, value);
      }
      return acc;
    }, new Map());
  };

  const pickKeyPairs = (payload: Record<string, TableColumn>) => {
    return Object.entries(payload).reduce<Record<string, string>>((acc, [key, value]) => {
      if (value.filter) {
        acc[key] = String(value.filter.field);
      }
      return acc;
    }, {});
  };

  useEffect(() => {
    const locationSearch = SearchParams.parse(history.location.search);
    setFilters(pickFilters(locationSearch));
  }, []);

  useEffect(() => {
    if (searchParams && !('detailedId' in searchParams)) {
      setFilters(pickFilters(searchParams));
    }
  }, [searchParams]);

  useEffect(() => {
    const payload = localSettingsState.columns;
    if (payload) {
      const keyPairs = pickKeyPairs(payload);
      if (!isEqual(keyPairs, filterKeyPairs)) {
        setFilterKeyPairs(keyPairs);
      }
    }
  }, [localSettingsState]);

  return (
    <FiltersContext.Provider
      value={{
        applyFilter,
        filters,
        setFilters,
        filterKeyPairs,
        combineQueryFilters
      }}>
      {children}
    </FiltersContext.Provider>
  );
};
