import React, { ReactNode, createContext, useContext, useRef, useState, useReducer, useEffect } from 'react';
import { CubeQuery } from '@netvision/lib-api-repo';
import { useApiRepository } from './useApiRepository';
import { useWidgetProps } from './useWidgetProps';
import { useSettings } from './useSettings';
import { useLocale } from './useLocale';
import { Toast } from 'primereact/toast';
import { dateTimeKeys, getTimestamp } from '../utils';
import { intersection } from 'lodash-es';

interface DataProviderProps {
  children: ReactNode
}

interface DataContext {
  journalData: Record<string, unknown>[]
  isLoading: boolean
  queryConfig: CubeQuery
  setQueryConfig: React.Dispatch<Partial<CubeQuery>>
  checkAndFetchPreviews: (data: Record<string, unknown>[]) => Promise<void>
  fetchJournalDataCount: () => Promise<void>
  fetchJournalData: () => Promise<void>
  fixTimeFields: (data: Record<string, unknown>[]) => Record<string, unknown>[]
  totalRecords: number
  toastRef: React.MutableRefObject<Toast>
}

const DataContext = createContext({
  journalData: [] as Record<string, unknown>[],
  isLoading: true,
  queryConfig: {} as CubeQuery,
  setQueryConfig: (payload: Partial<CubeQuery>) => {},
  checkAndFetchPreviews: (data: Record<string, unknown>[]) => {},
  fetchJournalDataCount: () => Promise.resolve(),
  fetchJournalData: () => Promise.resolve(),
  fixTimeFields: (data: Record<string, unknown>[]) => [] as Record<string, unknown>[],
  totalRecords: 0,
  toastRef: {} as React.MutableRefObject<Toast>
})

export const useData = () => useContext(DataContext)

export const DataProvider = ({ children }: DataProviderProps) => {
  const toastRef = useRef<Toast>(null!);
  const { api } = useApiRepository();
  const { $t } = useLocale();
  const { localSettingsState } = useSettings()
  const { entityName, withPreview } = useWidgetProps();
  const [isLoading, setIsLoading] = useState(true);
  const [journalData, setJournalData] = useState<Record<string, unknown>[]>(null!);
  const [isPreviewChecked, setIsPreviewChecked] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [queryConfig, setQueryConfig] = useReducer(
    (config: CubeQuery, payload: Partial<CubeQuery>): CubeQuery => ({ ...config, ...payload }),
    null!
  );

  const fixTimeFields = (entities: Record<string, unknown>[]) => {
    if (localSettingsState.columns) {
      return entities.map((entity) => {
        const timeFieldsIntersection = intersection<string>(
          dateTimeKeys,
          Object.keys(entity)
        )

        if (timeFieldsIntersection.length) {
          timeFieldsIntersection.forEach((key) => {
            entity[key] = getTimestamp(entity[key], localSettingsState.columns[key]?.multiplier)
          })
        }

        return entity
      })
    }

    return entities
  }

  const checkAndFetchPreviews = async () => {
    if (!('getPreview' in api)) {
      throw new Error('getPreview method isn\'t implemented')
    }

    const abr = new AbortController();

    try {
      const { signal } = abr;
      const blobedEntities = await Promise.all(
        journalData.map(async (el: Record<string, any>) => {
          const response = await api.getPreview({
            id: el.id,
            eventName: entityName,
            timestamp: Number(el.timestamp),
            signal
          });
          return { id: el.id, blob: response instanceof Blob ? URL.createObjectURL(response) : null };
        })
      );

      const mappedEntities = new Map(blobedEntities.map(({ id, blob }) => [id, blob]));
      const preparedData: Record<string, unknown>[] = journalData.map((entity) => ({
        ...entity,
        preview: mappedEntities.get(entity.id)
      }))

      setJournalData(preparedData);
    } catch (error) {
      console.error(error);
    } finally {
      abr.abort();
      setIsPreviewChecked(true);
    }
  }

  const fetchJournalData = async () => {
    if (!('cubeGetEntities' in api) || api.cubeGetEntities === undefined) {
      throw new Error('cubeGetEntities method isn\'t implemented')
    }

    setIsLoading(true);
    setIsPreviewChecked(false);

    try {
      const { results: entities } = await api
        .cubeGetEntities<Record<string, unknown>>(queryConfig, entityName);

      setJournalData(fixTimeFields(entities));
    } catch (error) {
      console.error(error);
      toastRef.current.show({
        severity: 'error',
        summary: $t('toastContent.error'),
        detail: $t('toastContent.dataLoadingError')
      });
    } finally {
      setIsLoading(false);
    }
  }

  const fetchJournalDataCount = async () => {
    if (!('cubeGetQuantity' in api) || api.cubeGetQuantity === undefined) {
      throw new Error('cubeGetQuantity method isn\'t implemented')
    }

    try {
      const countConfig = {
        ...queryConfig,
        offset: 0,
        dimensions: [],
        measures: [`${entityName}.count`]
      }
      delete countConfig.limit
      delete countConfig.order
      const count = await api.cubeGetQuantity(countConfig, entityName) || 0
      if (count !== totalRecords) {
        setTotalRecords(count)
      }
    } catch (error) {
      console.error(error)
      toastRef.current.show({
        severity: 'error',
        summary: $t('toastContent.error'),
        detail: $t('toastContent.countLoadingError')
      })
    }
  }

  useEffect(() => {
    if (!journalData?.length || !withPreview || isPreviewChecked) return
    checkAndFetchPreviews()
  }, [journalData])

  return (
    <DataContext.Provider value={{
      journalData,
      isLoading,
      queryConfig,
      fixTimeFields,
      setQueryConfig,
      checkAndFetchPreviews,
      fetchJournalDataCount,
      fetchJournalData,
      totalRecords,
      toastRef
    }}>
      {children}
    </DataContext.Provider>
  )
}
