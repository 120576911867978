export const localesEN = {
  settingsColumnTitle: 'Show columns',
  settingsGroupsTitle: 'Group rows',
  actions: {
    refresh: 'Refresh',
    export: 'Export',
    cancel: 'Cancel',
    back: 'Back',
    resetFilters: 'Reset filters',
    openSettings: 'Settings',
    byDefault: 'By default'
  },
  toastContent: {
    content: 'Event not found',
    error: 'Error',
    metadataLoadingError: 'Failed to get metadata',
    dataLoadingError: 'Failed to get data',
    countLoadingError: 'Failed to get data count'
  }
}
