import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { history } from '@netvision/lib-history';
import { GroupSettings, LocalSettings, TableColumn } from '../IWidgetProps';
import { useWidgetProps } from './useWidgetProps';
import { useMetadata } from './useMetadata';
import { useLocale } from './useLocale';
import { actializeLocalState } from '../utils';
import { name } from '../../package.json';
import { cloneDeep } from 'lodash-es';

interface SettingsProviderProps {
  children: ReactNode;
}

interface SettingsContext {
  localSettingsState: LocalSettings;
  setLocalSettingsState: React.Dispatch<React.SetStateAction<LocalSettings>>;
  resetSettingsState: () => void;
  resetGroupsState: () => void;
}

const SettingsContext = createContext<SettingsContext>({
  localSettingsState: {} as LocalSettings,
  setLocalSettingsState: () => { },
  resetSettingsState: () => { },
  resetGroupsState: () => { }
});

export const useSettings = () => useContext(SettingsContext);

export const SettingsProvider = ({ children }: SettingsProviderProps) => {
  const { setExternalLocales } = useLocale();
  const { metadata } = useMetadata();
  const { entityName, eventBus, eventBusID } = useWidgetProps();
  const [localSettingsState, setLocalSettingsState] = useState<LocalSettings>({} as LocalSettings);

  const resetSettingsState = () => {
    const activeGroups = localSettingsState.groups?.reduce<Map<string, GroupSettings>>((acc, next) => {
      next.isActive && acc.set(next.field, next)
      return acc
    }, new Map())

    setLocalSettingsState({
      ...localSettingsState,
      // При установке критериев группировки, порядок колонок автоматически меняется:
      // выбранные критерии смещаются в начало списка.
      // При сбросе настроек нельзя отменять этот порядок, нужно сначала проверить,
      // есть ли активные группировки, и оставить их в начале списка.
      // Для этого сначала берутся поля активных группировок, затем к ним добавляются все поля из order,
      // После чего этот массив уникализируется посредством Set, и переводится обратно в массив.
      order: activeGroups?.size
        ? [...new Set([...(Array.from(activeGroups.values() || []))
            .reduce<string[]>((acc, { isActive, field }) => {
              isActive && acc.push(field)
              return acc
            }, []), ...metadata.journal.order])]
        : cloneDeep(metadata.journal.order),
      columns: activeGroups?.size
        ? Object.entries(localSettingsState.columns)
            .reduce<Record<string, TableColumn>>((acc, [key, value]) => {
              if (activeGroups.has(key)) {
                acc[key] = value
              } else {
                acc[key] = cloneDeep(metadata.journal.columns[key])
              }
              return acc
            }, {})
        : cloneDeep(metadata.journal.columns)
    });
  };

  const resetGroupsState = () => {
    setLocalSettingsState({
      ...localSettingsState,
      groups: metadata.journal.groups?.map((group) => ({ ...group, isActive: Boolean(group.permanent) })) || [],
      order: [...metadata.journal.order],
      columns: cloneDeep(metadata.journal.columns),
      isGrouped: Boolean(metadata.journal.groups?.some(({ permanent }) => permanent))
    });
  };

  useEffect(() => {
    const cachedColumnsState = localStorage.getItem(`${entityName}:journal-settings`);
    if (cachedColumnsState) {
      const parsedCachedSettings = JSON.parse(cachedColumnsState) as Required<LocalSettings>;
      setLocalSettingsState(parsedCachedSettings);
      setExternalLocales(parsedCachedSettings.locales);
    }
  }, []);

  useEffect(() => {
    if (metadata) {
      setLocalSettingsState(actializeLocalState(metadata.journal, localSettingsState, metadata.locales));
      setExternalLocales(metadata.locales);
    }
  }, [metadata]);

  useEffect(() => {
    if (localSettingsState.columns) {
      localStorage.setItem(`${entityName}:journal-settings`, JSON.stringify(localSettingsState));

      history.push({
        search: history.location.search,
        hash: localSettingsState.isOpened ? '#settings' : ''
      });

      eventBus.notify(eventBusID, 'config:changed', {
        publisher: `${name}:${entityName}`,
        occurrenceTime: Date.now(),
        data: localSettingsState
      });
    }
  }, [localSettingsState]);

  return (
    <SettingsContext.Provider value={{
      localSettingsState,
      setLocalSettingsState,
      resetSettingsState,
      resetGroupsState
    }}>
      {children}
    </SettingsContext.Provider>
  )
}
