import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { CubeQuery } from '@netvision/lib-api-repo';
import { GlobalEventBus } from '@netvision/lib-types-frontend';
import { WidgetArea, WidgetChild, WidgetConfig } from '../IWidgetProps';

interface WidgetProviderProps {
  config: WidgetConfig
  children: ReactNode
}

interface WidgetPropsContext {
  entityName: string
  withPreview?: boolean
  detailEntityKey?: string
  isSwipableDetails?: boolean
  cubeListQuery: CubeQuery
  eventBusID: string
  eventBus: GlobalEventBus,
  areas: WidgetArea[]
  mountChildren: (domElement: HTMLElement, children: Array<WidgetChild | Record<string, unknown>>) => () => void
}

const WidgetPropsContext = createContext<WidgetPropsContext>({
  entityName: '',
  eventBusID: '',
  areas: [],
  withPreview: false,
  cubeListQuery: {},
  eventBus: {} as GlobalEventBus,
  mountChildren: () => () => {},
})

export const useWidgetProps = () => useContext(WidgetPropsContext)

export const WidgetProvider = ({ config, children }: WidgetProviderProps) => {
  const { areas, mountChildren, props: { entityName, detailEntityKey, isSwipableDetails, cubeListQuery, withPreview } } = config
  const [eventBusID, setEventBusID] = useState<string>(null!)
  const [eventBus, setEventBus] = useState<GlobalEventBus>(null!)

  useEffect(() => {
    if (!eventBus) {
      setEventBusID(config.props.eventBus.addUniqueSubscriberMap())
      setEventBus(config.props.eventBus)
    }
  }, [eventBus])

  return (
    <WidgetPropsContext.Provider value={{
      areas,
      mountChildren,
      entityName,
      withPreview,
      detailEntityKey,
      isSwipableDetails,
      cubeListQuery,
      eventBusID,
      eventBus
    }}>
      {children}
    </WidgetPropsContext.Provider>
  )
}
