/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { useEffect, useMemo, useState} from 'react';
import { PublishPayload } from '@netvision/lib-types-frontend';
import { CompareCellConfig } from '../IWidgetProps';
import { useWidgetProps } from '../hooks/useWidgetProps';
import { useApiRepository } from '../hooks/useApiRepository';
import { useSearchParams } from '../hooks/useSearchParams';
import { useData } from '../hooks/useData';
import { useMetadata } from '../hooks/useMetadata';
import { useSettings } from '../hooks/useSettings';
import { useLocale } from '../hooks/useLocale';
import { usePagination } from '../hooks/usePagination';
import { useFilters } from '../hooks/useFilters';
import { useSorting } from '../hooks/useSorting';
import { JournalHeader } from './JournalHeader';
import { JournalMount } from './JournalMount';
import { JournalPagination } from './JournalPagination';
import { JournalSettings } from './JournalSettings';
import { ProgressBar } from 'primereact/progressbar';
import { Dialog } from 'primereact/dialog';
import { dateTimeKeys, getTimestamp, prepareComparable } from '../utils';

interface JournalProps {
  selectEntity: (entity: Record<string, any>) => void;
}

export const Journal = ({ selectEntity }: JournalProps) => {
  const { $t, locale } = useLocale();
  const { api } = useApiRepository();
  const { searchParams } = useSearchParams();
  const { localSettingsState } = useSettings();
  const { journalPagination } = usePagination();
  const { sortConfig } = useSorting();
  const { filters, combineQueryFilters } = useFilters();
  const { cubeListQuery, entityName } = useWidgetProps();
  const { fetchMetadata, auxilaryMetadata } = useMetadata();
  const { isLoading, queryConfig, setQueryConfig, fetchJournalData, fetchJournalDataCount } = useData();
  const [isExportModalActive, setIsExportModalActive] = useState(false);

  const isNoAuxilaryMetadata = useMemo(() => (
    auxilaryMetadata === null || auxilaryMetadata.size > 0
  ), [auxilaryMetadata])

  const isLocalSettingsReady = useMemo(() => (
    Boolean(localSettingsState.columns)
  ), [localSettingsState.columns])

  const openDetailsPage = <T,>(payload: PublishPayload<T>) => {
    selectEntity(payload.data as Record<string, any>);
  };

  const checkAndGetDetails = async () => {
    if ('detailedId' in searchParams && 'cubeGetEntities' in api && api.cubeGetEntities) {
      try {
        const { results } = await api.cubeGetEntities<Record<string, unknown>>({
          ...cubeListQuery,
          filters: [
            {
              member: `${entityName}.id`,
              operator: 'equals',
              values: [String(searchParams.detailedId)]
            }
          ]
        }, entityName)
  
        const [entity] = results  
        const compareCell = Object.entries(localSettingsState.columns || {})
          .filter(([_, { cell }]) => cell.type === 'compare');
  
        if (entity) {
          const entityDateTimeKeys = Object.keys(entity).filter((key) => dateTimeKeys.includes(key))

          if (entityDateTimeKeys.length) {
            entityDateTimeKeys.forEach((key) => {
              entity[key] = getTimestamp(entity[key], localSettingsState.columns[key]?.multiplier)
            })
          }

          const compared = auxilaryMetadata
            ? compareCell.reduce((acc, [key, value]) => {
                acc = {
                  ...acc,
                  ...prepareComparable(
                    entity,
                    key,
                    locale,
                    auxilaryMetadata,
                    value.cell.config as CompareCellConfig
                  )
                }
                return acc
              }, {})
            : {}
  
          selectEntity({ ...entity, ...compared })
        }
      } catch (error) {
        console.error(error)
      }
    }
  }

  useEffect(() => {
    fetchMetadata();
  }, []);

  useEffect(() => {
    setQueryConfig({
      ...cubeListQuery,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      filters: combineQueryFilters(),
      offset: journalPagination.first,
      limit: journalPagination.rows,
      order: sortConfig
    });
  }, [filters, sortConfig, journalPagination]);

  useEffect(() => {
    if (!queryConfig || !isNoAuxilaryMetadata || !isLocalSettingsReady) return
    fetchJournalData();
    fetchJournalDataCount();
  }, [queryConfig, isNoAuxilaryMetadata, isLocalSettingsReady]);

  useEffect(() => {
    if (isLocalSettingsReady && isNoAuxilaryMetadata) {
      checkAndGetDetails();
    }
  }, [isLocalSettingsReady, isNoAuxilaryMetadata]);

  return (
    <div style={{ overflow: 'hidden' }}>
      <main css={mainCSS} id="eventTableMain">
        <JournalHeader />
        <div css={tabPanelCSS}>
          <ProgressBar
            mode="indeterminate"
            style={{ height: 'calc(2rem / var(--bfs))', opacity: isLoading ? 1 : 0 }}
          />
          <JournalMount openDetailsPage={openDetailsPage} />
          {localSettingsState.isOpened
            ? <JournalSettings />
            : <JournalPagination
                exportData={() => setIsExportModalActive(true)}
              />
          }
        </div>
      </main>
      <Dialog
        appendTo={document.getElementById('eventTableMain') || document.body}
        visible={isExportModalActive}
        onHide={() => setIsExportModalActive(false)}
        header={$t('actions.export')}
      />
    </div>
  )
}

const mainCSS = css`
  margin-top: calc(94rem/var(--bfs));
  margin-bottom: calc(30rem/var(--bfs));
  height: calc(100vh - 124rem/var(--bfs));
  position: relative;
  display: grid;
  grid-template-rows: auto 1fr;
  overflow: hidden;

  .p-dialog-mask:not(.p-dialog-visible) {
    z-index: -1 !important;
  }
`

const tabPanelCSS = css`
  padding: 0 calc(30rem/var(--bfs));
  display: grid;
  grid-template-rows: auto 1fr auto;
  overflow: hidden;
  position: relative;
`
