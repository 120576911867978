import React from 'react';
import { WidgetConfig } from './IWidgetProps';
import { ApiRepositoryProvider } from './hooks/useApiRepository';
import { WidgetProvider } from './hooks/useWidgetProps';
import { LocaleProvider } from './hooks/useLocale';
import { MetadataProvider } from './hooks/useMetadata';
import { DataProvider } from './hooks/useData';
import { SettingsProvider } from './hooks/useSettings';
import { SearchParamsProvider } from './hooks/useSearchParams';
import { PaginationProvider } from './hooks/usePagination';
import { FiltersProvider } from './hooks/useFilters';
import { SortingProvider } from './hooks/useSorting';
import { Main } from './components/Main';

const Root = (config: WidgetConfig) => {
  return (
    <ApiRepositoryProvider lib={config.props?.lib}>
      <RootContent config={config} />
    </ApiRepositoryProvider>
  );
};

const RootContent = ({ config }: { config: WidgetConfig }) => {
  return (
    <WidgetProvider config={config}>
      <LocaleProvider>
        <MetadataProvider>
          <SettingsProvider>
            <DataProvider>
              <SearchParamsProvider>
                <PaginationProvider>
                  <FiltersProvider>
                    <SortingProvider>
                      <Main />
                    </SortingProvider>
                  </FiltersProvider>
                </PaginationProvider>
              </SearchParamsProvider>
            </DataProvider>
          </SettingsProvider>
        </MetadataProvider>
      </LocaleProvider>
    </WidgetProvider>
  )
}

export default Root;
