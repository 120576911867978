import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { ISerializable, history, SearchParams } from '@netvision/lib-history';

interface SearchParamsProviderProps {
  children: ReactNode
}

interface SearchParamsContext {
  searchParams: ISerializable
  setSearchParams: React.Dispatch<React.SetStateAction<ISerializable>>
}

const SearchParamsContext = createContext<SearchParamsContext>({
  searchParams: {},
  setSearchParams: () => {}
})

export const useSearchParams = () => useContext(SearchParamsContext)

export const SearchParamsProvider = ({ children }: SearchParamsProviderProps) => {
  const [searchParams, setSearchParams] = useState<ISerializable>(null!)

  useEffect(() => {
    const locationSearch = SearchParams.parse(history.location.search)
    setSearchParams(locationSearch)
  }, [])

  useEffect(() => {
    if (searchParams && history.location.hash !== '#settings') {
      history.push({ search: SearchParams.stringify(searchParams) })
    }
  }, [searchParams])

  return (
    <SearchParamsContext.Provider value={{ searchParams, setSearchParams }}>
      {children}
    </SearchParamsContext.Provider>
  )
}
