import React, { ReactNode, createContext, useContext, useRef, useState } from 'react';
import { WidgetSchema } from '../IWidgetProps';
import { useApiRepository } from './useApiRepository';
import { useWidgetProps } from './useWidgetProps';
import { useLocale } from './useLocale';
import { Toast } from 'primereact/toast';
import { pick } from 'lodash-es';

interface MetadataProviderProps {
  children: ReactNode
};

interface MetadataContext {
  metadata: WidgetSchema;
  auxilaryMetadata: Map<string, WidgetSchema[]> | null
  fetchMetadata: () => void;
};

const MetadataContext = createContext({
  metadata: {} as WidgetSchema,
  auxilaryMetadata: new Map() as Map<string, WidgetSchema[]> | null,
  fetchMetadata: () => {},
});

export const useMetadata = () => useContext(MetadataContext);

export const MetadataProvider = ({ children }: MetadataProviderProps) => {
  const toastRef = useRef<Toast>(null!);
  const { api } = useApiRepository();
  const { entityName } = useWidgetProps();
  const { $t, setExternalLocales } = useLocale();
  const [metadata, setMetadata] = useState<WidgetSchema>(null!);
  const [auxilaryMetadata, setAuxilaryMetadata] = useState<Map<string, WidgetSchema[]> | null>(new Map());

  const fetchMetadata = async () => {
    try {
      const { results } = await api.getEntitiesList<WidgetSchema>({
        limiter: {
          id: `EntityTypeMetadata:${entityName}`,
          type: 'EntityTypeMetadata'
        },
      });

      const selectedMetadata = pick(
        Array.isArray(results) ? results[0] : results,
        ['id', 'entityType', 'journal', 'locales', 'type']
      );

      const cellWithMetadata =
        Object.entries(selectedMetadata.journal.columns)
          .reduce<[string, { type: string; id?: string }][]>((acc, [key, { cell }]) => {
            if ('metadata' in cell) {
              acc.push([key, cell.metadata as { type: string; id?: string }]);
            }
            return acc;
          }, []);

      if (cellWithMetadata.length) {
        const cellsMetadatas = await Promise.all(cellWithMetadata.map(async ([key, value]) => {
          const { results: res } = await api.getEntitiesList({
            limiter: {
              id: 'id' in value ? `EntityTypeMetadata:${value.id}` : undefined,
              type: value.type,
              limit: 1000
            }
          });
          return { [key]: res };
        }));

        setAuxilaryMetadata(cellsMetadatas.reduce((acc, next) => {
          Object.entries(next).forEach(([key, value]) => {
            acc.set(key, value)
          })
          return acc
        }, new Map()));
      } else {
        setAuxilaryMetadata(null);
      }
      
      setMetadata(selectedMetadata);
      setExternalLocales(selectedMetadata.locales);
    } catch (error: unknown) {
      console.error(error);
      toastRef.current.show({
        severity: 'error',
        summary: $t('toastContent.error'),
        detail: $t('toastContent.metadataLoadingError')
      });
      return;
    }
  }

  return (
    <MetadataContext.Provider value={{ metadata, auxilaryMetadata, fetchMetadata }}>
      {children}
    </MetadataContext.Provider>
  )
};
