/** @jsx jsx */
import {jsx, css} from '@emotion/react';
import {Fragment, useMemo} from 'react';
import {useWidgetProps} from '../hooks/useWidgetProps';
import {useLocale} from '../hooks/useLocale';
import {useSearchParams} from '../hooks/useSearchParams';
import {useData} from '../hooks/useData';
import {useSettings} from '../hooks/useSettings';
import {Checkbox} from 'primereact/checkbox';
import {Button} from 'primereact/button';
import {checkIsQFilterKey, cleanFilterProps} from '../utils';
import {name} from '../../package.json';
import {BinaryOperator} from '@netvision/lib-api-repo';

export const JournalHeader = () => {
  const {$t} = useLocale();
  const {isLoading} = useData();
  const {localSettingsState, setLocalSettingsState} = useSettings();
  const {searchParams, setSearchParams} = useSearchParams();
  const {entityName, eventBusID, eventBus} = useWidgetProps();

  const isFiltersEnabled = useMemo(
    () =>
      searchParams
        ? Object.keys(searchParams).filter((key) => checkIsQFilterKey(key as BinaryOperator)).length > 0
        : false,
    [searchParams]
  );

  const resetFilters = () => {
    eventBus.notify(eventBusID, 'resetFilters', {
      publisher: `${name}:${entityName}`,
      occurrenceTime: Date.now(),
      data: null
    });
  };

  const toggleGrouping = () => {
    setLocalSettingsState({
      ...localSettingsState,
      isGrouped: !localSettingsState.isGrouped
    });
  };

  return (
    <header css={headerCSS}>
      <div className="inner">
        <h1 className={!isLoading ? '--ready' : ''}>
          {$t('title')}
          {localSettingsState.isOpened && `: ${String($t('actions.openSettings')).toLowerCase()}`}
        </h1>
        <div className="inner-actions">
          {localSettingsState.isOpened ? (
            <Button
              className="p-button-secondary p-button-outlined p-button-sm"
              icon="mdi mdi-18px mdi-arrow-left p-c"
              tooltip={$t('actions.back')}
              onClick={() => {
                setLocalSettingsState({...localSettingsState, isOpened: false});
              }}
            />
          ) : (
            <Fragment>
              <Button
                className="p-button-secondary p-button-outlined p-button-sm"
                icon="mdi mdi-18px mdi-cog p-c"
                tooltip={$t('actions.openSettings')}
                onClick={() => {
                  setLocalSettingsState({...localSettingsState, isOpened: true});
                }}
              />
              {isFiltersEnabled && (
                <Button
                  className="p-button-outlined p-button-sm"
                  icon="mdi mdi-18px mdi-filter-remove p-c"
                  tooltip={$t('actions.resetFilters')}
                  onClick={() => {
                    setSearchParams(cleanFilterProps({offset: 0, limit: searchParams.limit || 0}));
                    resetFilters();
                  }}
                />
              )}
              {localSettingsState?.groups?.some(({isActive}) => isActive) && (
                <div className="inner-groups">
                  <Checkbox inputId="groupChecker" checked={localSettingsState.isGrouped} onChange={toggleGrouping} />
                  <label htmlFor="groupChecker" className="ml-2">
                    {$t('settingsGroupsTitle')}
                  </label>
                </div>
              )}
            </Fragment>
          )}
        </div>
      </div>
    </header>
  );
};

const headerCSS = css`
  padding: 0 calc(30rem / var(--bfs));

  .inner {
    position: relative;
    display: flex;
    align-items: center;
    height: calc(66rem / var(--bfs));

    &:after {
      content: '';
      position: absolute;
      bottom: calc(-2rem / var(--bfs));
      left: 0px;
      height: calc(1rem / var(--bfs));
      width: 100%;
      background: var(--text-color-secondary);
    }

    &-actions {
      margin-left: var(--spacer);
      display: flex;
      gap: var(--spacer-xs);
      flex: 1 1 0;
    }

    &-groups {
      margin-left: auto;
      display: flex;
      align-items: center;

      label {
        margin-left: var(--spacer-xs);
        user-select: none;
      }
    }
  }

  h1 {
    font-size: calc(24rem / var(--bfs));
    line-height: calc(30rem / var(--bfs));
    font-weight: 500;
    color: var(--text-color);
    margin: 0;
    position: relative;
    height: inherit;
    display: flex;
    align-items: center;

    &.--ready {
      &:after {
        content: '';
        left: 0px;
        width: 100%;
        z-index: 1;
        position: absolute;
        bottom: calc(-2rem / var(--bfs));
        height: calc(4rem / var(--bfs));
        background: var(--primary-color);
      }
    }
  }
`;
