import React, { useEffect, useMemo, useState } from 'react';
import { SearchParams, history } from '@netvision/lib-history';
import { SwipeButtonsState } from '../IWidgetProps';
import { useWidgetProps } from '../hooks/useWidgetProps';
import { usePagination } from '../hooks/usePagination';
import { useGetUserInfo } from '../hooks/useGetUserInfo';
import { useSearchParams } from '../hooks/useSearchParams';
import { useData } from '../hooks/useData';
import { Journal } from './Journal';
import { DetailsAdapter } from './DetailsAdapter';
import { Toast } from 'primereact/toast';
import { name } from '../../package.json';

export const Main = () => {
  const { pagination } = usePagination();
  const { userId = null } = useGetUserInfo();
  const { toastRef, journalData } = useData();  
  const { searchParams, setSearchParams } = useSearchParams();
  const { detailEntityKey, entityName, isSwipableDetails, eventBus, eventBusID } = useWidgetProps();
  const [selectedEntity, setSelectedEntity] = useState<null | Record<string, any>>(null);
  const [swipeState, setSwipeState] = useState<SwipeButtonsState | null>(null);
  const [lastSwipeAction, setLastSwipeAction] = useState<'prev' | 'next' | null>(null);

  const isCanPrev = useMemo(() => (
    Boolean(pagination.first > 0 || selectedEntity?.id !== journalData?.[0]?.id) || false
  ), [pagination, selectedEntity, journalData]);

  const isCanNext = useMemo(() => (
    Boolean(
      pagination.totalRecords - pagination.first > pagination.rows
        || selectedEntity?.id !== journalData?.[journalData.length - 1]?.id
    ) || false
  ), [pagination, selectedEntity, journalData]);

  const onPrev = () => {
    setLastSwipeAction('prev')
    const selectedEntityIndex = journalData.findIndex(({ id }) => (
      id === selectedEntity?.id
    ));

    const prevEntity = journalData[selectedEntityIndex - 1];

    if (prevEntity) {
      setSelectedEntity(prevEntity);
    } else if (isCanPrev) {
      setSearchParams({
        ...searchParams,
        limit: pagination.rows,
        offset: pagination.first + pagination.rows
      })
    }
  };

  const onNext = () => {
    setLastSwipeAction('next')
    const selectedEntityIndex = journalData.findIndex(({ id }) => (
      id === selectedEntity?.id
    ));

    const nextEntity = journalData[selectedEntityIndex + 1];

    if (nextEntity) {
      setSelectedEntity(nextEntity);
    } else if (isCanNext) {
      setSearchParams({
        ...searchParams,
        limit: pagination.rows,
        offset: pagination.first + pagination.rows
      });
    }
  };

  const onHide = () => {
    setSelectedEntity(null);
    setLastSwipeAction(null);
    delete searchParams.detailedId;
    setSearchParams({ ...searchParams });
  }

  useEffect(() => {
    if (
      !isSwipableDetails
      || !selectedEntity
      || !journalData?.length
      || !Reflect.has(pagination, 'first')
    ) {
      return setSwipeState(null);
    }

    setSwipeState({
      canPrev: isCanPrev,
      canNext: isCanNext
    })
  }, [isSwipableDetails, pagination, selectedEntity, journalData]);

  useEffect(() => {
    if (!isSwipableDetails || !selectedEntity) return;
    const locationSearch = SearchParams.parse(history.location.search);
    
    history.push({
      search: SearchParams.stringify({
        ...locationSearch,
        detailedId: selectedEntity.id
      })
    });

    eventBus.notify(eventBusID, 'entity:flipped', {
      publisher: name,
      occurrenceTime: Date.now(),
      data: {
        entity: selectedEntity,
        onPrev,
        onNext,
        onHide,
        ...swipeState
      }
    });
  }, [isSwipableDetails, selectedEntity, swipeState]);

  useEffect(() => {
    if (!journalData?.length || !lastSwipeAction) return;

    const extremeEntity = lastSwipeAction === 'next'
      ? journalData[0]
      : journalData[journalData.length - 1];

    setSelectedEntity(extremeEntity);
  }, [journalData]);
  
  return (
    <div>
      {userId &&
        <Journal selectEntity={setSelectedEntity} />
      }
      {(selectedEntity && journalData?.length && (!isSwipableDetails || swipeState)) &&
        <DetailsAdapter
          entity={selectedEntity}
          type={entityName}
          detailEntityKey={detailEntityKey}
          onHide={onHide}
          onPrev={onPrev}
          onNext={onNext}
          { ...swipeState }
        />
      }
      <Toast ref={toastRef} position="bottom-left" />
    </div>
  )
}