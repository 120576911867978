import React, { createContext, ReactNode, useContext, useMemo, useReducer, useEffect } from 'react';
import { ISerializable, SearchParams, history } from '@netvision/lib-history';
import { PaginatorPropsExtended } from '../IWidgetProps';
import { useSearchParams } from './useSearchParams';
import { useData } from './useData';
import { getPaginationKey } from '../utils';

interface PaginationProviderProps {
  children: ReactNode
}

interface PaginationContext {
  journalPagination: PaginatorPropsExtended
  pagination: PaginatorPropsExtended
}

const PaginationContext = createContext<PaginationContext>({
  journalPagination: {} as PaginatorPropsExtended,
  pagination: {} as PaginatorPropsExtended
})

export const usePagination = () => useContext(PaginationContext)

export const PaginationProvider = ({ children }: PaginationProviderProps) => {
  const { totalRecords } = useData()
  const { searchParams } = useSearchParams()
  const [journalPagination, setJournalPagination] = useReducer(
    (data: PaginatorPropsExtended, payload: Partial<PaginatorPropsExtended>) => ({ ...data, ...payload }),
    null!
  )

  const pagination = useMemo(() => ({
    ...journalPagination, totalRecords
  }), [journalPagination, totalRecords])

  const pickPagination = (payload: ISerializable) => {
    return Object.entries(payload)
      .reduce((acc, [key, value]) => {
        const parsedKey = getPaginationKey(key)
        if (parsedKey) {
          acc[parsedKey as keyof Partial<PaginatorPropsExtended>] = value
        }
        return acc
      }, {} as Partial<PaginatorPropsExtended>)
  }

  useEffect(() => {
    const locationSearch = SearchParams.parse(history.location.search)
    const payload = pickPagination(locationSearch)
    setJournalPagination({
      ...payload,
      rowsPerPageOptions: [10, 25, 50, 100],
      rows: payload.rows || 10,
      first: payload.first || 0
    })
  }, [])

  useEffect(() => {
    if (searchParams && !('detailedId' in searchParams)) {
      const newParams = pickPagination(searchParams)
      setJournalPagination({
        ...newParams,
        first: newParams.rows !== pagination.rows ? 0 : newParams.first || 0
      })
    }
  }, [searchParams])

  return (
    <PaginationContext.Provider value={{ journalPagination, pagination }}>
      {children}
    </PaginationContext.Provider>
  )
}
