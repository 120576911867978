import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { history, ISerializable, SearchParams } from '@netvision/lib-history';
import { PublishPayload } from '@netvision/lib-types-frontend';
import { CubeQuery } from '@netvision/lib-api-repo';
import { useWidgetProps } from './useWidgetProps';
import { SortingValues } from '../IWidgetProps';
import { isEqual } from 'lodash-es';
import { useSearchParams } from './useSearchParams';

interface SortingProviderProps {
  children: ReactNode
}

interface SortingContext {
  sorting: Map<string, SortingValues>
  applySorting<T>(payload: PublishPayload<T>): void
  sortConfig: CubeQuery['order']
}

const SortingContext = createContext<SortingContext>({
  sorting: new Map<string, SortingValues>(),
  applySorting<T>(payload: PublishPayload<T>) {},
  sortConfig: []
})

export const useSorting = () => useContext(SortingContext)

export const SortingProvider = ({ children }: SortingProviderProps) => {
  const { cubeListQuery } = useWidgetProps()
  const { setSearchParams, searchParams } = useSearchParams()
  const [sorting, setSorting] = useState<Map<string, SortingValues>>(new Map())

  const applySorting = <T,>(payload: PublishPayload<T>) => {
    const sortQuery = [...(payload.data as unknown as Map<string, SortingValues>)]?.[0]?.join(':')
    setSearchParams((prev) => {
      if (!sortQuery) {
        delete prev.sort
        return { ...prev }
      }

      return { ...prev, sort: sortQuery }
    })
  }

  const pickSorting = (payload: ISerializable): Map<string, SortingValues> => {
    const [key, value] = String(payload.sort)?.split(':')
    return key && value ? new Map([[key, Number(value)]]) : new Map()
  }

  const sortConfig = useMemo<CubeQuery['order']>(() => {
    const [sortKey, sortValue] = [...sorting.entries()]?.[0] || []
    if (sortKey === undefined) return cubeListQuery.order || []

    return [...new Map([
      ...Array.isArray(cubeListQuery.order)
        ? cubeListQuery.order
        : Object.entries(cubeListQuery.order || {}),
      [sortKey, sortValue === -1 ? 'desc' : 'asc']
    ])] as CubeQuery['order']
  }, [sorting])

  const typeSortValue = (value: string): SortingValues => {
    if ([0, 1, -1].includes(Number(value))) {
      return Number(value) as SortingValues
    }
    return 0
  }

  useEffect(() => {
    const [sortField, sortValue] = String(SearchParams.parse(history.location.search)?.sort)?.split(':')

    if (sortField && sortValue) {
      setSorting(new Map([[sortField, typeSortValue(sortValue)]]))
    }
  }, [])

  useEffect(() => {
    if (searchParams && !('detailedId' in searchParams)) {
      const pickedParams = pickSorting(searchParams)
      if (!isEqual(pickedParams, sorting)) {
        setSorting(pickedParams)
      }
    }
  }, [searchParams])

  return (
    <SortingContext.Provider value={{ sorting, applySorting, sortConfig }}>
      {children}
    </SortingContext.Provider>
  )
}
