/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useRef, useEffect } from 'react';
import { useWidgetProps } from '../hooks/useWidgetProps';

type DetailsProps = {
  entity: Record<string, any>;
  detailEntityKey?: string;
  type: string;
  canNext?: boolean;
  canPrev?: boolean;
  onHide: () => void;
  onPrev?: () => void;
  onNext?: () => void;
};

export const DetailsAdapter = ({
  type,
  entity,
  onHide,
  onPrev,
  onNext,
  canNext,
  canPrev,
  detailEntityKey = 'entity'
}: DetailsProps) => {
  const detailsRef = useRef<HTMLDivElement>(null);
  const { mountChildren, areas, entityName, eventBusID } = useWidgetProps();

  useEffect(() => {
    const { current: mountingPoint } = detailsRef;
    const detailsArea = areas.find(({ name }) => name === 'details');
    if (!detailsArea || !mountingPoint) throw new Error('Area for details widget is not defined');
    
    return mountChildren(
      mountingPoint,
      detailsArea.children.map((child) => {
        return {
          ...child,
          props: {
            ...child.props,
            [detailEntityKey]: { ...entity },
            eventBusID,
            showActions: true,
            eventName: entityName,
            entityType: entityName,
            onClose: onHide,
            onHide,
            onNext,
            onPrev,
            canNext,
            canPrev,
            type
          }
        };
      })
    );
  }, []);

  return <div ref={detailsRef} />;
};
